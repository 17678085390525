import { doPost } from '../utils/request';

export const getBlogs = async () => {
  try {
    const response = await doPost('/hr', {});
    return response;
  } catch (err: any) {
    return err;
  }
};

export const blogByTitle = async (title: string|undefined) => {
  try {
    const response = await doPost('/hrr',{title: title});
    return response;
  } catch (err: any) {
    return err;
  }
};