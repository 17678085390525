import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Carousel } from 'antd';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import './index.css'
import { CameraOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { HOME_CONTENT } from '../../utils/constant';
import { styles } from '../../styles';

const initItems = [
  {
    key: '4',
    icon: <PlayCircleOutlined />,
    title: 'Multiple Stream Choices for a Single Sports',
    content: 'In the features section of the Stream East App Network, you will find multiple sports and multiple streaming platforms foreach sport. Additional, each list of streamer for a specific sports is besed on the quality, performance, number of ads it shows and user experience. This list provides you with multiple choice of streamers and best on the streaming world.'
  },
  {
    key: '1',
    icon: <PlayCircleOutlined />,
    title: 'Free Access',
    content: 'Learn how you can catch up with live sporting events without having to pay for a subscription. Without a doubt, the most impressive aspect of StreamEast App is the opportunity to watch all the matches in different sports completely free of charge. Not like most streaming services that cost so much in membership, the listed streamers on StreamEast App offers its clients the service of watching their desired sports events for free. This makes it a perfect choice for those people who love sports events and wish to watch them online for free. Some of the events available are live sports events and one can actually watch the events without the subscription as well as payments. The users find a platform specific to their favorite sport and need.'
  },
  {
    key: '2',
    icon: <CameraOutlined  />,
    title: 'High-Definition Quality',
    content: 'The list of the streamers are best in business based on the quality of service they provide. They help you get to stream your games in high definition for that clear-action experience to your games. Currently StreamEast App aims at providing you list of streams with high definition, also referred to as HD, streaming quality to its users, thereby guaranteeing that users will be able to view their preferred content with a lot of ease. High definition makes the emotions of live sporting events even more sensational by offering clear, bright pictures and fluent motion resulting in the fans being able to observe everything that is happening on the field. These platforms rarely freezes or has interruptions even during the most popular events. Locating sharply focused on the HD quality, makes them more appealing for the users who care about the picture quality and seamless streaming, enhancing the appreciation of live sports.'
  },
  {
    key: '3',
    icon: <i className="fas fa-database"></i>,
    title: 'Wide Range of Sports',
    content: 'Watch a variety of sporting activities encompassing football, basketball, tennis, cricket, and so on in real time. These applications takes you direct to the live football matches and other related sports streaming procedures from across different parts of the world. Watch all your favorite leagues and teams including the English premier league, NBA, La Liga, Serie A, and NFL. The same action can be observed in high-quality video if you find yourself in any part of the world. This extensive reach makes Stream East  App platforms very appealing to the sports enthusiasts who want to follow different sports on one single site. These platforms has up to date schedule and offers all the prominent leagues and tournaments so that you can catch all the live streaming action'
  },
  {
    key: '4',
    icon: <i className="fas fa-database"></i>,
    title: 'Friendly User Interface',
    content: 'The layout of the applications is quite simple and thus easy to use. In terms of structures, functions, and interfaces, Stream East platforms are very easy to use and therefore navigating through it is very easy. The layout is quite simple with the main focus on delivering sports events that can be streamed with ease by the user. The organization and design of this application contain smooth categories on the page, a search bar function at the top and an accessible live stream function. This unification enables the users to start watching their favorite sports without having to struggle after installation of the program. The clean design greatly improves the ease of use, which makes Stream East App list easy to use for men and women of all ages with little technical knowledge.'
  },
  {
    key: '5',
    icon: <i className="fas fa-database"></i>,
    title: 'Multiple Device Compatibility',
    content: 'Stream East App list of streamers can be used on smartphone, tablets and computers. When it comes to compatibility, they are built to be easily used across various devices so that users have their live sports streaming on their most preferred device. Additionally, Our list is compatible with any device; be it a smartphone, a tablet, a computer, a laptop or any other device you choose to use. The compatibility of platgorms with multiple devices enables the following of live sporting events at the comfort of one’s preference.'
  },
];

export const About = () => {
  const matches = useMediaQuery('(min-width: 768px)');
  const [items, setItems] = useState(initItems);
  useEffect(() => {
    setItems(initItems);
  }, [items]);
  return (
    <div id="main-about" className="block aboutBlock bgGray">
      <div className="container-fluid">
        <div className="titleHolder">
          <a className="block-anchor" id="about" href="#about">
            Pseudo anchor
          </a>
          <h2>About Us</h2>
          <p lang="en">We help you find the most popular free sports streaming site: Live Sports Streaming Transformed</p>
        </div>
        <Row gutter={24} style={styles.flexDisplay}>
          <Col span={matches ? 22: 22}>
          <div id="home" className='heroBlock' style={{borderRadius: '10px'}}>
            <Carousel>
            {HOME_CONTENT.map((item, index) => {
                return (
                  <div key={index} className="container-fluid">
                    <div className="content">
                      {<h3>{item.title}</h3>}
                      <p style={{margin: '10px', fontSize: !matches ? '14px': ''}}>{item.content}</p>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
          </Col>
        </Row>
        <Row gutter={24} style={{display: 'flex', justifyContent: 'center'}}>
        <Col span={ 22} >
          <div className="contentHolder">
            <p className='about-para'>
            StreamEast App Network has the list of streamers and their specialties in streaming live sports. Applications that is perfect for the fans who wish to watch the live sports event. These application are developed and are transformed to sports lover around the world to have free access to sports streams. Under the Streameast Platform, dozens of application are developed with a single purpose in mind that is to provide a free service. Our goal is to help you select the best in the business for free sports streams and soccer streams. This makes StreamEast App Network ideal choice and a convenient platform for anyone who wishes to follow their favored sports. Additionally, this platform not only provides list of streamer but also have an extensive research on the quality of streams the platforms provide. Therefore, based on our research and experience, our list help you select specific platform for a specific sport. We have chosen platforms based on user experience and preferences.
            </p>
            <p className='about-para'>
            Due to the convenience of the user choice and quality of service they provide, the ranking of the platforms change and the number of user visit the website. These has risen to fame as a reliable platform where users can watch their favorite sports online without the disturbance of having to pay for subscriptions and more hidden costs. The focus on offering high and uninterrupted quality of streams is also one of the surreal values the StreamEast App, offers in the now oversaturated with different applications for watching sports content such as football, basketball, soccer and cricket. This list covers platforms for different sports and leagues and lets the user follow particular sport and its streamer and watch the game in real-time.
            </p>
            <p className='about-para'>
            A feature that makes StreamEast App Network particularly makes it stand out is its ability to combine a sport to its best streamer. This feature lets users have access to better choice and content. This is particularly advantageous to those who are searching for a platform which not only they can trust, but, other user as well who watch sports same as they do. Additionally, the platforms are not same for every sport. Every sport has it own favorite streamer and the position of the streamer will change based on the quality of the streams they provide.  All in all, StreamEast App Network is a reliable application to help you find best live sports streaming resources and platforms, which covers the extensive variety of sports and leagues for people from all around the globe.
            </p>
          </div>
        </Col>
        </Row>
        <Row style={{display: 'flex', justifyContent:'center'}}>
          <Col span={matches ? 18: 22}>
          <div className='contentHolder'>
            <h2>Top Features That Make Listed StreamEast Platforms a Sports Fans Best Friend</h2>
            <p>All of following features improve the user reliability and experience,making them toping our list and also making StreamEast App best network for finding best sports streaming platform. There are many aspects that can give users specific information and everything is perfectly tailored to the interest of sports enthusiasts on StreamEast platform. Some of the key features which we take into account to make to our list are as follow</p>
            <div className='points'>
              <img
              style={{width: matches ? '500px': '300px', marginTop: '10px'}} 
              src="https://res.cloudinary.com/dxm3d7mtu/image/upload/v1727706524/key-points_ukfgna.webp" 
              alt="feature-graph" 
              />
            </div>
          </div>
          </Col>
        </Row>
        <Row style={{margin: matches ? '2%': '0px'}}>
            {items.map(item => {
              return (
                <Col md={{ span: 8 }} key={item.key}>
                  <div className="content">
                    <Card title={item.title} className='infoCard'>
                      <Typography.Paragraph
                        style={{ fontSize: 'medium',
                          color: '#fff '}}
                        ellipsis={{ rows: 8, expandable: true, symbol: 'Read more' }}
                      >
                        {item?.content}
                      </Typography.Paragraph>
                    </Card>
                  </div>
                </Col>
              );
            })}
        </Row>
      </div>
    </div>
  );
};
