import { createStyles } from './utils';

export const styles = createStyles({
    header: {
        background: 'linear-gradient(360deg, rgb(113 113 113) 0%, rgb(73 52 52) 100%)' /* Gradient background */
    },
    flexDisplay: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    streamersContainer: {
        alignItems: 'start',
        border: '1px solid #545454',
        padding: '5px',
        borderRadius: '5px',
        boxShadow: '0 4px 5px rgba(24, 23, 23, 0.1)'
    },
    innerContainer: {
        background: 'linear-gradient(360deg, rgb(90, 85, 85) 0%, rgb(80 80 80) 100%)',
        padding: '5px'
    },
    streamerTitle:{
        textAlign: 'start',
        margin: '10px',
        color: '#000'
    },
    innerTitle: {
        color: '#f13939'
    },
    streamerParagraph:{    
        fontSize: 'medium',
        color: '#fff ',
        textAlign: 'start',
        margin: '10px',
    },
    mainBlogImage: {
        margin: '5px',
        borderRadius: '5px'
    },
    blogImage: {
        width: '240px',
        margin: '5px',
        borderRadius: '5px'
    },
    breakingNews: {
        padding: '0px',
    },
    scrollStyle: {
        maxHeight: '420px',
        overflowY: 'scroll',
        borderRadius: '5px',
        padding: '0px 10px 10px 10px',
        scrollbarWidth: 'none', // For Firefox
        scrollbarColor: 'rgb(90, 85, 85) rgb(67 64 64)', // Thumb and track colors for Firefox
        marginBottom: '15px'
    },
    slidePostDescription: {
        margin: '10px',
        color: 'rgb(240, 240, 240)',
        fontFamily: 'cursive',
        fontSize: '22px',
        fontWeight: 600
    },
    blogContainer: {
        margin:'10px',
        padding:'10px',
        marginTop: '5%',
        background: 'rgb(61 61 61)',
        borderRadius: '5px'
    },
    mainHeading: {
        color: '#fff', 
        fontFamily: 'math', 
        fontSize: '1.75rem',
        textAlign: 'start',
        marginTop: '10px'
    },
    blogHeading: {
        color: '#fff',
        fontFamily: 'math',
        fontWeight: '400',
        fontSize: '1.5rem',
        textAlign: 'start'
    },
    blogParagraph: {
        fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
        fontSize: '20px',
        textAlign: 'start',
        color: 'rgb(209 209 209)',
    },
    blogCategory: {
        color: 'rgb(198 198 198)',
        marginTop: '10px',
        fontSize: '14px',
        marginBottom: '0px',
    }
});